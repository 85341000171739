import React, { useCallback, useEffect, useState } from 'react';

import { delay } from 'lodash';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AutoComplete,
  Button,
  Col,
  Flex,
  Form,
  Input,
  Select,
  Typography,
} from 'antd';

import { Utils } from '../../../utils';
import style from '../style.module.scss';
import { messagesModal } from '../../../constants';
import { fileTypes } from '../../../helpers/input-helpers';
import { RootState, useStoreDispatch } from '../../../store';
import { FormDatePicker, FormInputNumber } from '../../../components/ui/Form';
import UploaderDocs from '../../../components/ui/UploaderDocs';
import { mapperSelect } from '../../../helpers/mappers-helpers';
import {
  addDayDate,
  disableDateAfter,
  disableDateBefore,
} from '../../../helpers/date-helpers';
import { IAgreement, IForm, setAgreement } from '../../../store/agreement';
import ModalConfirm from '../../../components/ui/ModalConfirm/ModalConfirm';
import { findCompany, mapperSearchCompany } from '../../../store/contractors';
import TooltipButton from '../../../components/ui/TooltipButton/TooltipButton';
import socket from '../../../socket';
import { SET_AGREEMENT } from '../../../services/rest_agreement';
import { Rest_files } from '../../../services/rest_files';

export const FormAgreements: React.FC<IForm> = ({
  onClose,
  isOpen,
  typeForm,
  initialValue,
  title = 'Создать договор',
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const dataCompany = useSelector(
    (state: RootState) => state.companies.dataCompany,
  );
  const dispatch = useStoreDispatch();

  const types = useSelector<RootState>((state) => state.agreement.types) as {
    id: number;
    value: string;
  }[];

  const isDisabled = !!initialValue?.id;
  const handlerSaveFiles = (files: any[]) => {
    form.setFieldsValue({ saveFiles: files });
  };

  const file_ids = Form.useWatch('file_ids', {
    form,
    preserve: true,
  });
  const date_signing = Form.useWatch('date_signing', {
    form,
    preserve: true,
  });
  const date_issue = Form.useWatch('date_issue', {
    form,
    preserve: true,
  });

  const defaultValues: IAgreement = {
    id: undefined,
    date_signing: null,
    date_issue: '',
    date_course: '',
    conversion: null,
    payment_term: '',
    date_string: '',
    agreement_id: '',
    type_id: null,
    executor_id: null,
    executor: '',
    client_id: null,
    client: '',
    approve_to_id: null,
    agreed: false,
    refused: undefined,
    file_ids: [],
    responsible_id: undefined,
    saveFiles: [],
  };

  useEffect(() => {
    if (!isOpen) return;
    if (isDisabled) {
      form.setFieldsValue({ ...defaultValues, ...initialValue });
    } else {
      const responsible = {
        avatar: Utils.getUserInfo().avatar,
        name: Utils.getUserInfo().fio,
        id: Utils.getUserInfo().id,
        isResponsible: true,
      };
      const responsible_id = responsible.id;

      form.setFieldsValue({
        ...defaultValues,
        responsible_id,
      });
    }
  }, [isDisabled, isOpen, typeForm]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    form.resetFields();
  };

  const onSubmit = async (values: IAgreement) => {
    try {
      setIsLoading(true);
      const { type_id } = values;
      values.type = types.find(({ id }) => id === type_id)?.value;
      if (values?.saveFiles?.length) {
        const res = await Rest_files.submitFiles(values.saveFiles)
        if (res.data?.length) {
          values.file_ids.push(...res.data);
        }
      }
      values.saveFiles = [];
      dispatch(setAgreement(values)).finally(() => {
        setIsLoading(false);
        handleClose();
        form.resetFields();
      });
    } catch (err) {
      setIsLoading(false);
      console.error('submit', err);
    } finally {
      socket.off(SET_AGREEMENT);
    }
  };

  const onUpdate = async (changedValues: any, allValues: any) => {};

  const onFinishError = async ({ values, errorFields, outOfDate }: any) => {
    if (!outOfDate && errorFields) {
      const isComplectCheckErrors = [];
      for (const errors of errorFields) {
        const title = errors?.[0];
        if (isComplectCheckErrors.length === 2) {
          break;
        }
      }
    }

    return;
  };

  const handleSelectCompany = useCallback((id: string, record: any) => {
    const fields = form.getFieldsValue();
    form.setFieldsValue({
      ...fields,
      client: record?.value,
      client_id: record?.id,
    });
  }, []);

  const handleSelectMyCompany = useCallback((id: string, record: any) => {
    form.setFieldValue('executor', record?.value);
    form.setFieldValue('executor_id', record?.id);
  }, []);

  const handleSearchBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    const item = dataCompany.find(
      (comp: any) => comp?.value === evt.target.value,
    );
    if (!item) {
      form.setFieldValue('client', '');
      form.setFieldValue('client_id', null);
    }
  };
  const handleSearchBlurMyCompany = (
    evt: React.FocusEvent<HTMLInputElement>,
  ) => {
    const item = dataCompany.find(
      (comp: any) => comp?.value === evt.target.value,
    );
    if (!item) {
      form.setFieldValue('executor', '');
      form.setFieldValue('executor_id', null);
    }
  };

  const handleSearchCompany = (text: string) => {
    if (text?.length >= 2) {
      delay(() => {
        dispatch(findCompany(text.toLocaleLowerCase()))
          .then((res) => {
            dispatch(mapperSearchCompany(res.payload));
          })
          .catch(console.error);
      }, 1000);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
    }
  }, [isOpen]);

  return (
    <Form
      className={style['tabs-form']}
      autoComplete="off"
      noValidate
      layout="vertical"
      form={form}
      initialValues={
        isDisabled ? { ...defaultValues, ...initialValue } : defaultValues
      }
      scrollToFirstError
      onFinish={onSubmit}
      onFinishFailed={onFinishError}
      onValuesChange={onUpdate}
    >
      <Form.Item name="saveFiles" noStyle />
      <Flex vertical gap={24}>
        <Flex justify="space-between" align="center">
          <Flex gap={10} align="center" style={{ marginTop: 15 }}>
            <Typography.Text className={style.title}>
              {initialValue?.id ? `Договор №${initialValue.id}` : t(title)}
            </Typography.Text>
          </Flex>
        </Flex>
        <Flex justify="space-between" align="start" gap={10}>
          <Col span={11}>
            <Form.Item name="id" noStyle />
            <Form.Item name="responsible_id" noStyle />
            <Form.Item
              label="Номер договора"
              name={'agreement_id'}
              rules={[{ required: true, message: t('Обязательное поле'), whitespace: true }]}
              required
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item name="date_string" noStyle />
            <Form.Item
              label="Дата подписания"
              name="date_signing"
              rules={[{ required: true, message: t('Обязательное поле') }]}
              required
            >
              <FormDatePicker
                isString={false}
                disabledDate={disableDateAfter}
                handlerChange={(str, data) => {
                  form.setFieldsValue({
                    date_string: str,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Flex>

        <Flex justify="space-between" align="start">
          <Col span={11}>
            <Flex vertical gap={25}>
              <Form.Item name="type" noStyle />
              <Form.Item
                label="Тип договора"
                name="type_id"
                rules={[{ required: true, message: t('Обязательное поле') }]}
                required
              >
                <Select options={mapperSelect({ items: types })} />
              </Form.Item>
              <Form.Item name="executor_id" noStyle />
              <Form.Item
                label="Исполнитель"
                name={'executor'}
                rules={[{ required: true, message: t('Обязательное поле') }]}
                required
              >
                <AutoComplete
                  popupClassName="certain-category-search-dropdown"
                  allowClear
                  options={dataCompany}
                  style={{ width: '100%' }}
                  onSearch={handleSearchCompany}
                  onSelect={handleSelectMyCompany}
                  onBlur={handleSearchBlurMyCompany}
                  placeholder="Исполнитель"
                />
              </Form.Item>
              <Form.Item name="client_id" noStyle />
              <Form.Item
                label="Заказчик"
                name="client"
                rules={[{ required: true, message: t('Обязательное поле') }]}
                required
              >
                <AutoComplete
                  popupClassName="certain-category-search-dropdown"
                  allowClear
                  options={dataCompany}
                  style={{ width: '100%' }}
                  onSearch={handleSearchCompany}
                  onSelect={handleSelectCompany}
                  onBlur={handleSearchBlur}
                  placeholder="Заказчик"
                />
              </Form.Item>
              <Form.Item name="refused" required noStyle />
            </Flex>
          </Col>
          <Col span={11}>
            <Form.Item name="file_ids" noStyle />
            <Typography.Text
              className={style.label}
            >
              {t('Файлы')}
            </Typography.Text>
            <div
              className={classnames(style['file-box'], 'all-custom-v-scroll')}
            >
              <UploaderDocs
                isOpen={isOpen}
                ids={file_ids}
                multiple
                accept={[
                  fileTypes.doc,
                  fileTypes.xls,
                  fileTypes.pdf,
                  fileTypes.jpeg,
                ].join(',')}
                setDataIds={(files) => {
                  form.setFieldValue('file_ids', files);
                }}
                handlerSaveFiles={handlerSaveFiles}
              />
            </div>
          </Col>
        </Flex>
        <Flex justify="space-between" align="start" gap={10}>
          <Col span={11}>
            <Form.Item
              label="Дата выставления счета"
              name="date_issue"
              rules={[{ required: true, message: t('Обязательное поле'), whitespace: true }]}
              required
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Курс на"
              name="date_course"
              rules={[{ required: true, message: t('Обязательное поле'), whitespace: true }]}
              required
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Flex>
        <Flex justify="space-between" align="start" gap={10}>
          <Col span={11}>
            <Form.Item name="id" noStyle />
            <Form.Item name="responsible_id" noStyle />
            <Form.Item
              label="% конвертации"
              name={'conversion'}
              rules={[{ required: true, message: t('Обязательное поле') }]}
              required
            >
              <FormInputNumber allowClear />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Срок оплаты"
              name="payment_term"
              rules={[{ required: true, message: t('Обязательное поле'), whitespace: true }]}
              required
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Flex>
      </Flex>
      <Form.Item>
        <Flex
          gap={8}
          justify={'space-between'}
          style={{ margin: '32px auto 10px auto', maxWidth: '500px' }}
        >
          <TooltipButton
            propsTooltip={{
              title: initialValue?.id
                ? t('Сохранить изменения')
                : t('Добавить'),
            }}
            propsButton={{
              type: 'primary',
              htmlType: 'submit',
              style: { maxWidth: '290px', minWidth: '290px' },
              disabled: isLoading,
            }}
          >
            {isLoading
              ? '...Сохранение'
              : initialValue?.id
              ? t('Сохранить изменения')
              : t('Добавить')}
          </TooltipButton>
          <Button
            onClick={() => setConfirmation(true)}
            type={'text'}
            style={{ maxWidth: '134px', color: '#E14453' }}
            disabled={isLoading}
          >
            {t('Отменить')}
          </Button>
        </Flex>
      </Form.Item>

      <ModalConfirm
        title={messagesModal.warning.title}
        subtitle={messagesModal.warning.subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={handleClose}
      />
    </Form>
  );
};
