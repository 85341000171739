import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { defaultLocationReturn, defaultRelease, findOneRelease, ITabForm } from '../../../../../../store/rent';
import {
  AutoComplete,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import style from '../../../style.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useStoreDispatch } from '../../../../../../store';
import { delay } from 'lodash';
import { findCompany } from '../../../../../../store/contractors';
import {
  findAgreementsCompany,
  getDealsIds,
  IDeal,
} from '../../../../../../store/sales';
import { findReleaseId, IContainer } from '../../../../../../store/rent';
import socket from '../../../../../../socket';
import {
  columnsAccounts,
  columnsDeals,
  dataCondition,
  minLimitBet,
} from '../../../constants';
import {
  FormInputNumber,
  FormSelectSearch,
  FormTable,
} from '../../../../../../components/ui/Form';
import { FormList } from '../../../../../../components/ui/Form/FormList/FormList';
import { Utils } from '../../../../../../utils';
import { mapperSearchYandexLocation } from './utils';
import {
  FIND_ONE_RELEASE,
  FIND_RELEASES_ID,
} from '../../../../../../services/rest_releases';
import {
  getContainersAndDeals,
  filterContainers,
  mapperCompany,
} from '../../../utils';
import { GET_DEALS_SALES_IDS } from '../../../../../../services/rest_deal';

const TabDeal: React.FC<ITabForm> = ({ isOpen, isDisabled }) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const form = Form.useFormInstance();
  const [dataAgreements, setDataAgreements] = useState<any>([]);
  const [currentPageDeal, setCurrentPageDeal] = useState(1);
  const [pageDeal, setPageDeal] = useState(20);
  const [dataCompany, setDataCompany] = useState<any[]>([]);

  const dataTypeContainers = useSelector(
    (state: RootState) => state.types.dataTypeContainers,
  );

  const dataCurrency = useSelector(
    (state: RootState) => state.stores.dataCurrency,
  );
  const dataPercent = useSelector(
    (state: RootState) => state.stores.dataPercent,
  );
  const dataMyCompany = useSelector(
    (state: RootState) => state.companies.dataMyCompany,
  );

  const related_deals: IDeal[] = Form.useWatch('related_deals', {
    form,
    preserve: true,
  });
  const releases: { release_id: string; ids: any[] }[] = Form.useWatch(
    'releases',
    {
      form,
      preserve: true,
    },
  );
  const related_accounts: any[] = Form.useWatch('related_accounts', {
    form,
    preserve: true,
  });

  const count_day: number = Form.useWatch('count_day', {
    form,
    preserve: true,
  });
  const containers: IContainer[] = Form.useWatch('containers', {
    form,
    preserve: true,
  });
  const my_company_id = Form.useWatch('my_company_id', {
    form,
    preserve: true,
  });
  const company_id = Form.useWatch('company_id', { form, preserve: true });
  const typeId = Form.useWatch('type_deal_id', { form, preserve: true });

  useEffect(() => {
    if (company_id && my_company_id) {
      setDataAgreements([]);
      form.setFieldsValue({
        agreement: '',
        agreement_id: null,
      });
      const payload =
        typeId === 2
          ? { executor_id: my_company_id, client_id: company_id }
          : { executor_id: company_id, client_id: my_company_id };
      dispatch(findAgreementsCompany(payload))
        .then((res) => {
          setDataAgreements(res.payload);
        })
        .finally(() => {
          socket.off('find-agreements-company');
        });
    } else {
      setDataAgreements([]);
      form.setFieldsValue({
        agreement: '',
        agreement_id: null,
      });
    }
  }, [company_id, my_company_id, typeId]);

  useEffect(() => {
    if (!company_id) {
      form.setFieldsValue({
        containers: [],
        related_deals: [],
        related_deals_ids: [],
      });
    }
  }, [company_id]);

  const onDeleteRelease = (
    item: { release_id: string; ids: any[] },
    index: string | number,
  ) => {
    const findContainer = containers.find(
      (cont) => cont?.release?.release_id === item?.release_id,
    );
    const deals = findContainer
      ? related_deals?.filter(
          (deal) => deal?.id_deal !== findContainer?.deal?.id_deal,
        )
      : related_deals;
    form.setFieldValue(
      'containers',
      containers.filter(
        (cont) => cont?.release?.release_id !== item?.release_id,
      ),
    );
    form.setFieldValue('related_deals', deals);
    form.setFieldValue(
      'related_deals_ids',
      deals.map((deal) => deal?.id),
    );
  };

  const handleSelectCompany = useCallback((id: string, record: any) => {
    const fields = form.getFieldsValue();
    form.setFieldsValue({
      ...fields,
      phone: record?.phone,
      company: record?.value,
      company_id: record?.id,
      contact_id: record?.contact_id,
    });
  }, []);

  const handleSelectMyCompany = useCallback((id: string, record: any) => {
    form.setFieldValue('account_num', record?.score);
    form.setFieldValue('my_company', record?.value);
    form.setFieldValue('my_company_id', record?.id);
  }, []);

  const handleSearchBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    const item = dataCompany.find(
      (comp: any) => comp?.value === evt.target.value,
    );
    if (!item) {
      form.setFieldValue('company', '');
      form.setFieldValue('company_id', null);
    }
  };
  const handleSearchBlurMyCompany = (
    evt: React.FocusEvent<HTMLInputElement>,
  ) => {
    const item = dataMyCompany.find(
      (comp: any) => comp?.value === evt.target.value,
    );
    if (!item) {
      form.setFieldValue('my_company', '');
      form.setFieldValue('my_company_id', null);
    }
  };
  const handleSearchCompany = (text: string) => {
    if (text?.length >= 2) {
      delay(() => {
        dispatch(findCompany(text.toLocaleLowerCase()))
          .then((res) => {
            setDataCompany(mapperCompany(res.payload));
          })
          .catch(console.error);
      }, 1000);
    }
  };

  return (
    <Flex vertical>
      <Flex vertical gap={10} style={{ marginBottom: '24px' }}>
        <Row gutter={[12, 11]} justify="space-between">
          <Col span={11}>
            <Flex vertical gap={10}>
              <Form.Item
                label={t(typeId < 8 ? 'Клиент' : 'Поставщик')}
                name={'company'}
                className={classnames(style.label, {
                  [style.label__disabled]: !!form.getFieldValue('id'),
                })}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <AutoComplete
                  popupClassName="certain-category-search-dropdown"
                  style={{ width: '100%' }}
                  allowClear
                  placeholder={t('Выберите клиента')}
                  options={dataCompany}
                  onSelect={handleSelectCompany}
                  onSearch={handleSearchCompany}
                  onBlur={handleSearchBlur}
                  disabled={isDisabled}
                />
              </Form.Item>
              <Flex vertical gap={10}>
                <Form.Item label={t('Договор №')} name={'agreement'}>
                  <Select
                    optionLabelProp="label"
                    options={dataAgreements}
                    onSelect={(_, opt) =>
                      form.setFieldValue('agreement_id', opt?.id)
                    }
                    disabled={!dataAgreements?.length}
                  />
                </Form.Item>
                <Form.Item
                  label={t('Наше юридическое лицо')}
                  name={'my_company'}
                  className={classnames(style.label, {
                    [style.label__disabled]: !!form.getFieldValue('id'),
                  })}
                  rules={[{ required: true, message: t('Обязательное поле') }]}
                >
                  <AutoComplete
                    popupClassName="certain-category-search-dropdown"
                    style={{ width: '100%' }}
                    allowClear
                    placeholder={t('Выберите компанию')}
                    options={dataMyCompany}
                    onSelect={handleSelectMyCompany}
                    onBlur={handleSearchBlurMyCompany}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Flex>
              <Row gutter={12} style={{ marginLeft: 0 }}>
                <FormList
                  nameList="releases"
                  title="№ Релиза"
                  titleName="release_id"
                  titleNames={["id"]}
                  file="Релиз и заявка с поставщиком"
                  fileName="ids"
                  defaultItem={defaultRelease}
                  onDelete={onDeleteRelease}
                  disabled={!company_id}
                  type="search"
                  filterSelect={(item: any) =>
                    !releases?.find(({ release_id }) => release_id === item)
                  }
                  typeOption={{
                    style: { maxWidth: '195px' },
                    allowClear: false,
                    showSearch: true,
                    placeholder: 'Поиск',
                    suffixIcon: null,
                    notFoundContent: null,
                    onSelect: (val: any, opt: any) => {
                      if (opt?.id) {
                        dispatch(findOneRelease(opt.id))
                          .then((res) => {
                            if (res?.payload?.currentContainers?.length) {
                              const { resultContainers, ids } =
                                getContainersAndDeals({
                                  containers: filterContainers({
                                    containers,
                                    releases,
                                    release_id: opt.id,
                                  }),
                                  currentContainers:
                                    res.payload.currentContainers,
                                });
                              form.setFieldValue(
                                'containers',
                                resultContainers,
                              );
                              dispatch(
                                getDealsIds({
                                  current: currentPageDeal,
                                  page: pageDeal,
                                  clear: '',
                                  order: 'DESC',
                                  ids,
                                }),
                              )
                                .then((res) => {
                                  form.setFieldValue(
                                    'related_deals',
                                    res.payload?.rows || [],
                                  );
                                  form.setFieldValue('related_deals_ids', ids);
                                })
                                .finally(() => {
                                  socket.off(GET_DEALS_SALES_IDS);
                                });
                            }
                          })
                          .finally(() => {
                            socket.off(FIND_ONE_RELEASE);
                          });
                      }
                    },
                    api: (val: any) =>
                      dispatch(findReleaseId(val)).finally(() => {
                        socket.off(FIND_RELEASES_ID);
                      }),
                    mapResponse: (res: any) => res?.payload,
                  }}
                />
              </Row>
              <Row gutter={12} style={{ marginLeft: 0 }}>
                <Form.Item
                  name="location_release"
                  label="Локация релиза"
                  style={{ flex: 1 }}
                >
                  <FormSelectSearch
                    allowClear
                    showSearch
                    placeholder={'Поиск'}
                    suffixIcon={null}
                    filterOption={false}
                    notFoundContent={null}
                    api={Utils.yandexCity}
                    mapResponse={mapperSearchYandexLocation}
                  />
                </Form.Item>
              </Row>
              <Row gutter={12} style={{ marginLeft: 0 }}>
                <FormList
                  nameList="location_return"
                  title="Локация возврата"
                  titleName="location"
                  type="search"
                  defaultItem={defaultLocationReturn}
                  typeOption={{
                    style: { maxWidth: '360px' },
                    allowClear: true,
                    showSearch: true,
                    placeholder: 'Поиск',
                    suffixIcon: null,
                    filterOption: false,
                    notFoundContent: null,
                    api: Utils.yandexCity,
                    mapResponse: mapperSearchYandexLocation,
                  }}
                />
              </Row>
              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item label={t('Ставка')} name={'bet'}>
                    <FormInputNumber min={minLimitBet} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item noStyle name="currency_id" />
                  <Form.Item label={t('Валюта')} name={'currency'}>
                    <Select
                      optionLabelProp="label"
                      options={dataCurrency}
                      onSelect={(_, opt) =>
                        form.setFieldValue('currency_id', opt?.id)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t('НДС')} name={'nds'}>
                    <Select optionLabelProp="label" options={dataPercent} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item label={t('Тип')} name={'type_container'}>
                    <Select
                      optionLabelProp="label"
                      options={dataTypeContainers}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t('Состояние')} name={'condition'}>
                    <Select optionLabelProp="label" options={dataCondition} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t('Кол-во КТК')} name={'count_ktk'}>
                    <FormInputNumber allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item
                    label={t('Кол-во дней')}
                    rules={[
                      { required: true, message: t('Обязательное поле') },
                    ]}
                    name={'count_day'}
                  >
                    <FormInputNumber allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t('СНП')} name={'snp'}>
                    <Input allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Form.Item
                  label={t('Штраф за отмену')}
                  style={{ flex: 1 }}
                  name={'fine'}
                  rules={[
                    {
                      required: true,
                      message: t('Обязательное поле'),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input allowClear />
                </Form.Item>
              </Row>
              <Row gutter={12}>
                <Form.Item
                  label={t(
                    `Тема письма с ${typeId < 8 ? 'клиентом' : 'подрядчиком'}`,
                  )}
                  name={'message_subject'}
                  style={{ flex: 1 }}
                  rules={[
                    {
                      required: true,
                      message: t('Обязательное поле'),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input allowClear />
                </Form.Item>
              </Row>
              <Row gutter={12}>
                <Form.Item
                  label={t('Дополнительно')}
                  name={'additionally'}
                  style={{ flex: 1 }}
                >
                  <Input allowClear />
                </Form.Item>
              </Row>
            </Flex>
          </Col>
          <Col span={12}>
            <Flex vertical style={{ minHeight: '100%' }}>
              <Row gutter={12} style={{ maxHeight: '450px' }}>
                <FormTable
                  label="Связанные сделки"
                  tableLayout="fixed"
                  scroll={related_deals?.length > 1 ? { y: 250 } : undefined}
                  defaultColumns={columnsDeals}
                  name="related_deals"
                />
              </Row>
              <Divider />
              <Row gutter={12} style={{ maxHeight: '450px' }}>
                <FormTable
                  label="Счет"
                  tableLayout="fixed"
                  scroll={related_accounts?.length ? { y: 250 } : undefined}
                  defaultColumns={columnsAccounts}
                  name="related_accounts"
                />
              </Row>
              <Space
                direction={'vertical'}
                style={{ marginTop: 'auto', textAlign: 'end' }}
              >
                <Typography.Link
                  className={style['text-card-14']}
                  disabled={!isDisabled}
                >
                  {t('Отправить запрос на выставление счета')}
                </Typography.Link>
                <Typography.Link
                  className={style['text-card-14']}
                  disabled={!isDisabled}
                >
                  {t('Отправить запрос на оплату счета')}
                </Typography.Link>
              </Space>
            </Flex>
          </Col>
        </Row>
      </Flex>
    </Flex>
  );
};

export default TabDeal;
