import { Avatar, Col, Divider, Flex, Form, Input, Row, Typography } from 'antd';
import Icon, { UserOutlined } from '@ant-design/icons';
import { EditIcon, EmailIcon, flagRuIcon } from '../../assets/image/svg';
import Button from '../../components/ui/Button/Button';
import FormContacts from './formContact';
import DrawerComponent from '../../components/DrawerComponent';
import CopyButton from '../../components/ui/CopyButton/CopyButton';
import './style.scss';
import { Icons } from './Icons';
import { useTranslation } from 'react-i18next';

const { Text, Title } = Typography;

const ViewContacts = (props: any) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex vertical>
        <Flex justify={'space-between'} className={'drawer-header'}>
          <Flex vertical style={{ width: '100%' }}>
            <Flex justify={'space-between'} align={'center'}>
              <h2 className={'drawer-title'}>
                {props.dataContacts?.full_name}
              </h2>
              <Icon component={EditIcon} onClick={props.handleShowEditForm} />
            </Flex>
            <h3>Источник</h3>
            <h4>{props.dataContacts.source}</h4>
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            <Flex gap={4} vertical>
              <h3>Должность</h3>
              <h4>{props.dataContacts.position}</h4>
            </Flex>
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            <Flex gap={4} vertical>
              <Flex gap={4} vertical>
                <h3>Контакты</h3>
                <Row>
                  <Col className="gutter-row" span={10}>
                    <Text>{props?.dataContacts?.type_phone_first}</Text>
                  </Col>
                  {props?.dataContacts?.phone_first && (
                    <>
                      <Col className="gutter-row" span={12}>
                        <Flex gap={4}>
                          <Icon component={flagRuIcon} />
                          <Text>{props?.dataContacts?.phone_first}</Text>
                        </Flex>
                      </Col>
                      <Col className="gutter-row" span={2}>
                        <CopyButton text={props?.dataContacts?.phone_first} />
                      </Col>
                    </>
                  )}
                </Row>
                {props?.dataContacts?.last_phone?.map(
                  (item: any, index: number) => {
                    return (
                      <Row key={index}>
                        <Col className="gutter-row" span={10}>
                          <Text>{item.add_type_phone}</Text>
                        </Col>
                        {item.add_phone && (
                          <>
                            <Col className="gutter-row" span={12}>
                              <Flex gap={4}>
                                <Icon component={flagRuIcon} />
                                <Text>{item.add_phone}</Text>
                              </Flex>
                            </Col>
                            <Col className="gutter-row" span={2}>
                              <CopyButton text={item.add_phone} />
                            </Col>
                          </>
                        )}
                      </Row>
                    );
                  },
                )}
              </Flex>
              <Flex gap={4} vertical>
                {props?.dataContacts?.email_first !== null && (
                  <Row>
                    <Col className="gutter-row" span={10}>
                      <Text>{props?.dataContacts?.type_email_first}</Text>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Flex gap={4} align={'center'}>
                        <Button icon={<EmailIcon />} type={'icon'} />
                        <Text>{props?.dataContacts?.email_first}</Text>
                      </Flex>
                    </Col>
                    <Col className="gutter-row" span={2}>
                      <CopyButton text={props?.dataContacts?.email_first} />
                    </Col>
                  </Row>
                )}
                {props?.dataContacts?.last_email?.map(
                  (item: any, index: number) => {
                    return (
                      <Row key={index}>
                        <Col className="gutter-row" span={10}>
                          <Text>{item.add_type_email}</Text>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Flex gap={4}>
                            <Button icon={<EmailIcon />} type={'icon'} />
                            <Text>{item.add_email}</Text>
                          </Flex>
                        </Col>
                        <Col className="gutter-row" span={2}>
                          <CopyButton text={item.add_email} />
                        </Col>
                      </Row>
                    );
                  },
                )}
              </Flex>
              <Flex gap={4} vertical>
                {props?.dataContacts?.messenger_first !== null && (
                  <Row>
                    <Col className="gutter-row" span={10}>
                      <Text>{props?.dataContacts?.type_messenger_first}</Text>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Flex gap={4}>
                        <Icons.arrIcon
                          type={props?.dataContacts?.type_messenger_first}
                        />
                        <Text>{props?.dataContacts?.messenger_first}</Text>
                      </Flex>
                    </Col>
                    <Col className="gutter-row" span={2}>
                      <CopyButton text={props?.dataContacts?.messenger_first} />
                    </Col>
                  </Row>
                )}
                {props?.dataContacts?.last_messenger?.map(
                  (item: any, index: number) => {
                    return (
                      <Row key={index}>
                        <Col className="gutter-row" span={10}>
                          <Text>{item.add_type_messenger}</Text>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Flex gap={4}>
                            <Icons.arrIcon type={item.add_type_messenger} />
                            <Text>{item.add_messenger}</Text>
                          </Flex>
                        </Col>
                        <Col className="gutter-row" span={2}>
                          <CopyButton text={item.add_messenger} />
                        </Col>
                      </Row>
                    );
                  },
                )}
              </Flex>
            </Flex>
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            <Flex gap={4} vertical>
              <h3>Компания</h3>
              {props?.dataContacts?.company !== null &&
                props?.dataContacts?.company.map((item: any, index: number) => (
                  <h5 key={index}>{item}</h5>
                ))}
            </Flex>
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            <Flex gap={4} vertical>
              <h3>Тип контакта</h3>
              <Text>{props.dataContacts.type_contact}</Text>
            </Flex>
            <Divider />
            <Flex gap={6} vertical>
              <h3 style={{ marginBottom: 0 }}>Дополнительно</h3>
              <h4 style={{ marginTop: 0 }}>Ответственный</h4>
              <Flex
                justify={'space-between'}
                align={'center'}
                style={{ paddingRight: 7 }}
              >
                <Flex gap={6} align={'center'}>
                  <Avatar size={28} icon={<UserOutlined />} />
                  <Text style={{ marginTop: 0, color: '#05F' }}>
                    {props.dataContacts.fio}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex vertical style={{ marginTop: 6 }}>
              <Title level={4} style={{ marginBottom: 6 }}>
                Комментарий
              </Title>
              <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 20 }}
                defaultValue={props?.dataContacts?.comment}
                placeholder="Комментарии"
                disabled
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap={8}
          justify="end"
          style={{ margin: '32px 10px', maxWidth: '500px' }}
        >
          <Button
            onClick={props?.onClose}
            type="primary"
            style={{ width: '174px' }}
          >
            {t('Закрыть')}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default ViewContacts;
