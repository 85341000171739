import { ComponentType } from "react";
import CrmContainers from "../components/CrmContainers";
import ForgetPassword from '../pages/auth/ForgetPassword';
import Login from "../pages/auth/Login";
import RegistrationForm from "../pages/auth/Registration";
import { RouteNames } from "./names";
import DepoContainers from "../components/DepoContainers";
import Terminals from "../pages/terminals/Terminals";
import Page404 from "../pages/Page404";
import SalesDeals  from "../pages/sales/deals/Deals";
import Containers from "../pages/containers/Containers";
// import ContainersOne from "../pages/containers/ContainersOne";
import ViewPageDeal from "../pages/sales/deals/ViewPageDeal";
import EditPageSalesDeal from "../pages/sales/deals/EditPageDeal";
import EditPageRentDeal from "../pages/rent/deals/EditPageDeal";
import RentDeals  from "../pages/rent/deals/Deals";
import RentRequests from "../pages/rent/requests/Requests";
import Releases from "../pages/releases";



export interface IRoute {
    path: string;
    component: ComponentType;
    exact?: boolean;
}
export interface IRoutes extends IRoute {
    children?: IRoute[],
}

export const publicRoutes: IRoutes[] = [
    { path: RouteNames.LOGIN, exact: false, component: Login },
    { path: RouteNames.REGISTRATION, exact: false, component: RegistrationForm },
    { path: RouteNames.FORGET_PASSWORD, exact: false, component: ForgetPassword },
    { path: RouteNames.NOT_FOUND, exact: true, component: Login },
]

export const privateRoutes: IRoutes[] = [
    { path: RouteNames.MAIN, exact: true, component: CrmContainers },
    { path: RouteNames.CRM, exact: true, component: CrmContainers},
    { path: RouteNames.CRM_ID, exact: false, component: CrmContainers},
    { path: RouteNames.DEPOT, exact: false, component: DepoContainers },
    { path: RouteNames.CONTAINERS, exact: true, component: Containers },
    // { path: RouteNames.CONTAINERS_ONE, exact: true, component: ContainersOne },
    { path: RouteNames.CONTAINERS_ID, exact: false, component: Containers },
    { path: RouteNames.TERMINALS, exact: true, component: Terminals },
    { path: RouteNames.TERMINALS_ID, exact: false, component: Terminals },
    { path: RouteNames.SALES_DEAL, exact: true, component: SalesDeals },
    { path: RouteNames.SALES_DEAL_VIEW_ID, exact: true, component: ViewPageDeal },
    { path: RouteNames.SALES_DEAL_ID, exact: true, component: EditPageSalesDeal},
    { path: RouteNames.RELEASES, exact: true, component: Releases },
    { path: RouteNames.RELEASES_ID, exact: false, component: Releases },
    { path: RouteNames.RENT_DEAL, exact: true, component: RentDeals },
    { path: RouteNames.RENT_DEAL_ID, exact: true, component: EditPageRentDeal},
    { path: RouteNames.RENT_REQUESTS, component: RentRequests, exact: true, },
    { path: RouteNames.NOT_FOUND, exact: true, component: Page404 },
]
