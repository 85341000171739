import React from 'react';
import { Typography } from 'antd';
import { IColumnsEditTable } from '../../../components/ui/Form/FormTable/FormTable';
import { RouteNames } from '../../../router/names';
import { openInNewTab } from '../../../helpers/link-helper';

export const TAB_DEAL = 'tabDeal';
export const TAB_CONTAINERS = 'tabContainers';
export const TAB_EXPENSES = 'tabExpenses';

export const TABLE_RENT_DEAL = 'tableRentDeal';
export const ROW_RENT_DEAL = 'rowRentDeal';
export const COUNT_RENT_DEAL = 'countRentDeal';

export const FORMAT_DATE_KTK = 'DD.MM.YYYY';

export const dataCondition = [
  {value: 'NEW', label: 'NEW'},
  {value: 'CW', label: 'CW'},
  {value: 'IICL', label: 'IICL'},
]

export const nameColumnsExpenses = [
  'Наименование',
  'Ставка',
  'Оплата поставщику',
  'Согласование'
];
export const keyColumnsExpenses = [

];

// ктк для excel
export const nameColumnsKtk = [
  '№',
  'Релиз',
  '№КТК',
  'Тип',
  'Оплата подрядчику',
  'Ставка клиенту',
  'Дата выдачи',
  'Дата сдачи по заявке',
  'Дата сдачи',
  'Кол-во дней СНП',
  'Сумма СНП ',
];
export const keyNamesKtk = [
  'index',
  'release',
  'num_container',
  'type_container',
  'payment',
  'bet',
  'departure_date',
  'delivery_request_date',
  'delivery_date',
  'count_day_snp',
  'total_snp',
];
export const statusColorsDeal = [
  {
    title: 'Новый',
    color: '#00B288',
    background: '#E6F6EC',
  },
  {
    title: 'Ожидаем оплаты',
    color: '#F29100',
    background: '#FEF4E6',
  },
  {
    title: 'Выдан без оплаты',
    color: '#445371',
    background: '#EDEEF1',
  },
  {
    title: 'Продан',
    color: '#0055FF',
    background: '#F2F6FF',
  },
  {
    title: 'Просрочено',
    color: '#E14453',
    background: '#FCE8EA',
  },
];

export const columnsDeals: IColumnsEditTable[] = [
  {
    title: 'Тип',
    dataIndex: 'type_deal',
    width: '30%'
  },
  {
    title: '№',
    dataIndex: 'id_deal',
    width: '30%',
    render: (val, rec, index) => {
      return (
        <Typography.Link
          ellipsis
          disabled={!rec?.['id']}
          onClick={() =>
            openInNewTab(`${RouteNames.SALES_DEAL_VIEW}${rec['id']}`)
          }
        >
          {val}
        </Typography.Link>
      );
    },
  },
  {
    title: 'Клиент',
    dataIndex: 'company',
    width: '30%',
  },
];
export const columnsAccounts: IColumnsEditTable[] = [
  {
    title: 'Счет',
    dataIndex: 'account',
    width: '30%'
  },
  {
    title: 'Сумма',
    dataIndex: 'total',
    width: '30%'
  },
  {
    title: 'Статус счета',
    dataIndex: 'status',
    width: '30%'
  },
];

export const minLimitBet = -1000000;

export const dealNames = ['message_subject', 'my_company', 'company', 'count_day', 'fine', ];
export const expensesNames = ['expenses'];

export const noStyleField = [
  'id',
  'type_deal',
  'type_deal_char',
  'my_company_id',
  'company_id',
  'contact_id',
  'agreement_id',
  'related_deals_ids',
];
