import React, { useMemo, useRef } from 'react';

import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';

import style from '../style.module.scss';
import Responsible from './list/Responsible';
import ITabs from '../../../../components/ui/Tabs';
import { useParamsHistory } from '../../../../hooks';
import { TabDeal, TabDoc, TabRepair } from './tabs/view';
import { ITab } from '../../../../components/ui/Tabs/Tabs';
import { IFormDeal } from '../../../../store/sales';
import ScrollList from '../../../../components/ui/ScrollList';
import { TAB_DEAL, TAB_DOCUMENT, TAB_REPAIR } from '../constants';
import CopyButton from '../../../../components/ui/CopyButton/CopyButton';
import TooltipButton from '../../../../components/ui/TooltipButton/TooltipButton';
import {
  CopyBufferIconDefault,
  EditViewIconDefault,
} from '../../../../assets/image/svg';
import { URL_API_USER } from '../../../../api/config';

const ViewDeal: React.FC<IFormDeal> = ({ onClose, onEdit, isOpen, initialValue: deal }) => {
  const { t } = useTranslation();
  const refResponsible = useRef<HTMLDivElement>(null);
  const { location } = useParamsHistory();
  const { pathname } = location;

  const items: ITab[] = useMemo(
    () => [
      {
        key: TAB_DEAL,
        label: 'Сделка',
        forceRender: isOpen,
        content: <TabDeal isOpen={isOpen} deal={deal} />,
      },
      {
        key: TAB_DOCUMENT,
        label: 'Документы',
        forceRender: isOpen,
        content: <TabDoc isOpen={isOpen} deal={deal} />,
      },
      {
        key: TAB_REPAIR,
        label: 'В ремонте',
        forceRender: isOpen,
        content: <TabRepair isOpen={isOpen} deal={deal} />,
      },
    ],
    [isOpen],
  );
  return (
    <div className={style['tabs-form']}>
      <Flex vertical style={{ width: '100%', height: '100%' }} justify={'space-between'}>
        <Flex
          justify={'space-between'}
          align={'center'}
          style={{ marginBottom: 24 }}
        >
          <h2 className={style.title}>
            {t('Сделка')} {deal?.id_deal}
          </h2>
          <Flex gap={2} align={'center'}>
            <TooltipButton
              propsTooltip={{ title: t('Редактировать'), placement: 'topLeft' }}
              propsButton={{
                hidden: !!deal?.delete || !onEdit || !deal?.edit,
                icon: <EditViewIconDefault />,
                type: 'text',
                className: style.icon,
                style: { padding: 0, height: 'auto' },
                onClick: onEdit,
              }}
            />
            <CopyButton
              className={style.icon}
              text={URL_API_USER + pathname}
              textSuccess={t('Ссылка скопирована')}
              icon={CopyBufferIconDefault}
            />
          </Flex>
        </Flex>
        <ITabs className={style['tabs-form__tab']} tabs={items} />
      </Flex>
      <Flex
        gap={20}
        vertical
      >
        <div className={style['box-list']}>
          {!!deal?.responsible?.length && (
            <ScrollList
              setLastItemReached={(v) => v}
              height={
                deal?.responsible?.length >= 3
                  ? 200
                  : (deal?.responsible?.length || 1) * 80
              }
              totalTableActivity={1}
              data={deal?.responsible}
              tableRef={refResponsible}
              paginationActivity={() => {}}
              isTable={false}
              endMessage={null}
            >
              {deal?.responsible.map((cont, i) => (
                <Responsible
                  key={i}
                  title={cont.name}
                  avatar={cont.avatar}
                  description={
                    cont?.id === deal?.responsible_id ? t('Ответственный') : ''
                  }
                />
              ))}
            </ScrollList>
          )}
        </div>

      <Flex
        gap={8}
        justify="space-between"
        style={{ margin: '32px 0 10px auto', maxWidth: '500px' }}
      >
        <Button onClick={onClose} type="primary" style={{ width: '174px' }}>
          {t('Закрыть')}
        </Button>
      </Flex>
        </Flex>
    </div>
  );
};

export default ViewDeal;
