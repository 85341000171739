import React, {useEffect, useState} from 'react';
import Icon from '@ant-design/icons';
import {
    Flex,
    Form,
    Input,
    Select,
    Row,
    Col,
    Typography,
    DatePicker,
    AutoComplete,
    App,
    Button,
    DatePickerProps,
    notification,
} from 'antd';
import dayjs from 'dayjs';
import {deleteBlack} from '../../assets/image/svg';
import moment from 'moment';
import {
    ICurrency,
    ITypeEmail,
    ITypeMessenger,
    ITypePhone,
} from '../../types';
import {RootState, useStoreDispatch} from '../../store';
import {findCompany} from '../../store/contractors';
import {getCurrency} from '../../store/store';
import {SelectSearch} from '../../components/ui/SelectSearch/SelectSearch';
import {Utils} from '../../utils';
import Uploader from '../../components/ui/Uploader/Uploader';
import NumericInput from '../../components/ui/NumericInput/NumericInput';
import FormContractors from '../contractors/formContractors';
import DrawerComponent from '../../components/DrawerComponent';
import ModalConfirm from '../../components/ui/ModalConfirm/ModalConfirm';
import {saveContractors} from '../../store/contractors';
import {messagesModal} from '../../constants';
import {disabledDateFromStartToEnd} from '../../helpers/date-helpers';
import {CREATE} from '../../helpers/string-helpers';
import {useSelector} from 'react-redux';
import socket from '../../socket';
import {findContainers, getStock} from '../../store/containers';
import {UploadProps} from 'antd/lib/upload';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';

const {title: warningTitle, subtitle} = messagesModal.warning;

const {Text, Link} = Typography;

interface Props {
    title?: string;
    onClose?: any;
    dataRowContainer?: any;
    form?: any;
    typeForm?: string;
    onFinishForm?: (data: any) => void;
    dataTypePhone?: Array<ITypePhone>;
    dataTypeEmail?: Array<ITypeEmail>;
    dataTypeMessenger?: Array<ITypeMessenger>;
}

const createSvgIcon = (svgString: string) => () =>
    <div dangerouslySetInnerHTML={{__html: svgString}}/>;

const FormContainer: React.FC<Props> = ({
                                            title,
                                            onClose,
                                            dataRowContainer,
                                            form,
                                            typeForm,
                                            onFinishForm,
                                            dataTypePhone,
                                            dataTypeEmail,
                                            dataTypeMessenger,
                                        }) => {
    const {message} = App.useApp();
    const [api, contextHolder] = notification.useNotification();
    const cityData = useSelector((state: RootState) => state.terminals.dataCity);
    const dataCurrency = useSelector(
        (state: RootState) => state.stores.dataCurrency,
    );
    const dataTypeQuality = useSelector(
        (state: RootState) => state.types.dataTypeQuality,
    );
    const dataTypeContainers = useSelector(
        (state: RootState) => state.types.dataTypeContainers,
    );
    const dataStatusContainer = useSelector(
        (state: RootState) => state.containers.dataStatusContainer,
    );

    const [confirmation, setConfirmation] = React.useState<boolean>(false);
    const dispatch = useStoreDispatch();
    const [dateMemory, setMemory] = useState<any>(null);
    const [dateCvv, setDateCvv] = useState<any>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [openAddCompany, setOpenAddCompany] = useState<boolean>(false);
    const [openCvv, setOpenCvv] = useState(false);
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const [optionsStock, setOptionsStock] = useState<[]>([]);
    const [icons, setIcons] = useState<{ [key: string]: JSX.Element }>({});
    const [terminal, setTerminal] = useState<any>([]);
    const [dataId, setDataId] = useState<any[]>([]);
    const [terminalName, setTerminalName] = useState<string | null>(null);
    const [valuePrice, setValuePrice] = useState<number | null>(null);
    const [numContainer, setNumContainer] = useState<string>('');
    const [disabledSave, setDisabledSave] = useState<boolean>(false);
    const [dataRequisites, setDataRequisites] = useState<any>([]);
    const [dataBankRequisites, setDataBankRequisites] = useState([]);
    const [ownerId, setOwnerId] = useState<number | null>(null);
    const [arHistory, setArHistory] = useState<any[]>([
        [
            {id: 0, meaning: null},
            {id: 1, meaning: null},
        ],
        [
            {id: 0, meaning: null},
            {id: 1, meaning: null},
        ],
        [
            {id: 0, meaning: null},
            {id: 1, meaning: null},
        ],
        [
            {id: 0, meaning: null},
            {id: 1, meaning: null},
        ],
        [
            {id: 0, meaning: null},
            {id: 1, meaning: null},
        ],
        [
            {id: 0, meaning: null},
            {id: 1, meaning: null},
        ],
        [
            {id: 0, meaning: null},
            {id: 1, meaning: null},
        ],
        [
            {id: 0, meaning: null},
            {id: 1, meaning: null},
        ],
        [
            {id: 0, meaning: null},
            {id: 1, meaning: null},
        ],
        [
            {id: 0, meaning: null},
            {id: 1, meaning: null},
        ],
        [
            {id: 0, meaning: null},
            {id: 1, meaning: null},
        ],
        [
            {id: 0, meaning: null},
            {id: 1, meaning: null},
        ],
        [
            {id: 0, meaning: null},
            {id: 1, meaning: null},
        ],
        [
            {id: 0, meaning: null},
            {id: 1, meaning: null},
        ],
    ]);

    const createObj = (
        val: string | number | string[],
        found: boolean,
        name: string,
        arIndex: number,
        propName: string,
    ) => {
        if (found) {
            return {
                id: arIndex,
                status_id: null,
                user_id: null,
                container_id: null,
                meaning: null,
            };
        } else {
            return {
                id: arIndex,
                status_id: dataStatusContainer.find((item) => item.name === name)?.id,
                user_id: Utils.getUserInfo().id,
                container_id: dataRowContainer?.id,
                meaning: val,
            };
        }
    };

    useEffect(() => {
        dispatch(getCurrency()).then((response) => {
            response.payload.forEach((currency: ICurrency) => {
                const SvgIcon = createSvgIcon(currency.svg);
                setIcons((prev) => ({...prev, [currency.id]: <SvgIcon/>}));
            });
        });
        if (dataRowContainer) {
            const dateCvv =
                dataRowContainer?.date_cvv !== null
                    ? moment(dataRowContainer?.date_cvv).format('DD.MM.YYYY')
                    : 'Нет даты';
            const dateMemory =
                dataRowContainer?.date_memory !== null
                    ? moment(dataRowContainer?.date_memory).format('DD.MM.YYYY')
                    : 'Нет даты';

            setDateCvv(dateCvv);
            setMemory(dateMemory);
            setTerminalName(dataRowContainer?.child_terminal?.name);
            form.setFieldsValue({
                num_container: dataRowContainer?.num_container,
                type_container: dataRowContainer?.type_container,
                quality: dataRowContainer?.quality,
                owner: dataRowContainer?.contractors?.name_company,
                comment: dataRowContainer.comment,
                currency: dataRowContainer?.currency,
                price: dataRowContainer?.price,
                arrival_date: dataRowContainer?.arrival_date
                    ? dayjs(dataRowContainer.arrival_date)
                    : null,
                departure_date: dataRowContainer?.departure_date
                    ? dayjs(dataRowContainer.departure_date)
                    : null,
            });
        }
        setValuePrice(dataRowContainer?.price);

        socket.on('res-get-stock', resGetStock);
        socket.on('res-create-contractors', handleCreateContractors);
        return () => {
            socket.off('res-create-contractors', handleCreateContractors);
        };
    }, [dataRowContainer?.id]);

    const resGetStock = (response: any) => {
        console.log(response);
        setOptionsStock(response);
    }

    const handleCreateContractors = (response: any) => {
        if (response.success === 1) {
            setOwnerId(response.data.id);
        } else {
            setOwnerId(response.data.id);
            message.error(response.message);
        }
    };

    const getFindCompany = (searchText: any) => {
        if (searchText.length > 2) {
            dispatch(findCompany(searchText.toLowerCase())).then((response) => {
                setOptions(response.payload);
            });
        }
    };

    const onFinish = (data: any) => {
        Object.assign(data, {type_form: typeForm});
        data.owner_id = ownerId !== null ? ownerId : dataRowContainer?.owner_id;
        data.terminal_id =
            terminal.length > 0
                ? terminal[0].value
                : dataRowContainer?.child_terminal?.city_terminals?.id;
        data.terminal = terminalName;
        data.child_terminal_id =
            terminal.length > 0
                ? terminal[0].child
                : dataRowContainer?.child_terminal?.id;
        data.date_entry = moment().format('YYYY-MM-DD');
        data.ids_photo =
            dataId.length > 0
                ? dataId
                : dataRowContainer?.ids_photo !== null
                    ? dataRowContainer?.ids_photo
                    : null;
        data.responsible_id = Utils.getUserInfo().id;
        data.status_id = typeForm === 'create' ? 1 : 10;
        data.price = valuePrice === null ? null : valuePrice;

        data.user_id = Utils.getUserInfo().id;
        data.id = dataRowContainer?.id;
        data.photo_bool = dataId.length > 0 ? 'есть' : 'нет';
        data.terminal =
            dataRowContainer?.child_terminal?.name !== null
                ? dataRowContainer?.child_terminal?.name
                : dataRowContainer?.terminal?.name;
        data.currency_price = valuePrice;
        if (dataId.length === 0) {
            data.ids_photo = null;
        }
        data.history = arHistory;
        if (onFinishForm) {
            onFinishForm(data);
        }
    };

    const onFinishCompany = (data: any) => {
        data.bank = dataBankRequisites.length > 0 ? dataBankRequisites : null;
        data.short_address =
            dataRequisites.length > 0 ? dataRequisites[10]?.meaning : null;
        data.contractors_id = data.id;

        form.setFieldsValue({
            owner: data.name_company,
            owner_id: ownerId,
        });
        dispatch(saveContractors(data));
        setOpenAddCompany(false);
    };

    const handleCloseCvv = () => {
        setOpenCvv(false);
    };

    const handleCloseMemory = () => {
        setOpen(false);
    };

    const onChangeNumContainer = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const convertToEng = Utils.convertToEnglishUppercase(event.target.value);
        setNumContainer(convertToEng);
        form.setFieldsValue({num_container: convertToEng});
    };

    const updateArHistory = (obj: any) => {
        setArHistory((prevArHistory) =>
            prevArHistory.map((item) =>
                item.id === obj.id ? {...item, ...obj} : item,
            ),
        );
    };

    const handleAddOwner = () => {
        setOpenAddCompany(true);
    };

    const handleCompany = () => {
        setOptions([]);
    };

    const onCloseDrawerCompany = () => {
        setOpenAddCompany(false);
    };

    const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    };

    const updateArHistoryStatus = (
        val: string | number | string[],
        propName: string,
        changeDescription: string,
        arIndex: number,
    ) => {
        if (typeForm === 'update') {
            const isFind = [dataRowContainer].some(
                (item: any) => item[propName] === val,
            );
            const obj = createObj(val, isFind, changeDescription, arIndex, propName);
            const index = arHistory.findIndex((item) => item.id === obj.id);
            if (index !== -1) {
                setArHistory([
                    ...arHistory.slice(0, index),
                    {...arHistory[index], ...obj},
                    ...arHistory.slice(index + 1),
                ]);
            }
        }
    };

    const onBlurNumContainer = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const isValid = Utils.isValidContainerNumber(event.target.value);
        if (!isValid && event.target.value.length !== 11) {
            message.warning('Неверный формат номера контейнера!');
            setDisabledSave(true);
        } else {
            dispatch(findContainers(event.target.value)).then((response) => {
                if (typeForm === 'update') {
                    const obj =
                        response.payload.length === 0
                            ? arHistory[0].map((item: any, index: number) => {
                                if (index === 0) {
                                    item.meaning = dataRowContainer.num_container;
                                    item.status_id = dataStatusContainer.find(
                                        (item: any) => item.name === 'Номер контейнера',
                                    )?.id;
                                    item.user_id = Utils.getUserInfo().id;
                                    item.container_id = dataRowContainer?.id;
                                } else {
                                    item.meaning = event.target.value;
                                    item.status_id = dataStatusContainer.find(
                                        (item: any) => item.name === 'Номер контейнера',
                                    )?.id;
                                    item.user_id = Utils.getUserInfo().id;
                                    item.container_id = dataRowContainer?.id;
                                }
                            })
                            : arHistory[0].map((item: any, index: number) => {
                                if (index === 0) {
                                    item.meaning = null;
                                } else {
                                    item.meaning = null;
                                }
                            });
                    if (response.payload.length > 0) {
                        Utils.InfoOpenNotification(
                            'topRight',
                            'Номер такого контейнера уже есть в базе!',
                            5,
                            api,
                        );
                    }
                    setDisabledSave(false);
                }
                setDisabledSave(false);
            });
        }
    };

    const selectTypeContainer = (val: string) => {
        if (typeForm === 'update') {
            const isFind = [dataRowContainer].some(
                (item: any) => item['type_container'] === val,
            );
            if (!isFind) {
                arHistory[1].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = dataRowContainer.type_container;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Тип контейнера',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    } else {
                        item.meaning = val;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Тип контейнера',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    }
                });
            } else {
                arHistory[1].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = null;
                    } else {
                        item.meaning = null;
                    }
                });
            }
        }
    };

    const selectQualityContainer = (val: string) => {
        if (typeForm === 'update') {
            const isFind = [dataRowContainer].some(
                (item: any) => item['quality'] === val,
            );
            if (!isFind) {
                arHistory[2].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = dataRowContainer.quality;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Состояние',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    } else {
                        item.meaning = val;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Состояние',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    }
                });
            } else {
                arHistory[2].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = null;
                    } else {
                        item.meaning = null;
                    }
                });
            }
        }
    };

    const onSelectCompany = (_: string, record: any) => {
        setOwnerId(Number(record.id));
        if (typeForm === 'update') {
            const isFind = [dataRowContainer].some(
                (item: any) => item['owner_id'] === Number(record.id),
            );
            if (!isFind) {
                arHistory[3].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = dataRowContainer.contractors.name_company;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Собственник',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    } else {
                        item.meaning = record.value;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Собственник',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    }
                });
            } else {
                arHistory[3].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = null;
                    } else {
                        item.meaning = null;
                    }
                });
            }
        }
    };

    const onBlurComment = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (typeForm === 'update') {
            const isFind = [dataRowContainer].some(
                (item: any) => item['comment'] === event.target.value,
            );
            if (!isFind) {
                arHistory[4].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = dataRowContainer.comment;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Собственник',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    } else {
                        item.meaning =
                            event.target.value === '' ? null : event.target.value;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Собственник',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    }
                });
            } else {
                arHistory[4].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = null;
                    } else {
                        item.meaning = null;
                    }
                });
            }
        }
    };

    const onChangeSelect = (event: any, option: any) => {
        const terminalMame =
            event !== undefined
                ? typeof option.children === 'string'
                    ? option.children
                    : option.children.props.children
                : null;
        setTerminalName(terminalMame);
        if (typeof option.key === 'string') {
            setTerminal([JSON.parse(option.value)]);
        } else {
            setTerminal({value: option.value, child: 0});
        }
        if (typeForm === 'update') {
            const isFind = [dataRowContainer].some(
                (item: any) => item['terminal'] === terminalMame,
            );
            if (!isFind) {
                arHistory[5].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = dataRowContainer.child_terminal.name;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Терминал',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    } else {
                        item.meaning = terminalMame;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Терминал',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    }
                });
            } else {
                arHistory[5].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = null;
                    } else {
                        item.meaning = null;
                    }
                });
            }
        }
    };

    const handleCurrency = (val: string, record: any) => {
        if (typeForm === 'update') {
            const isFind = [dataRowContainer].some(
                (item: any) => item['currency'] === val,
            );
            if (!isFind) {
                arHistory[6].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = dataRowContainer.currency;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Валюта',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    } else {
                        item.meaning = val;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Валюта',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    }
                });
            } else {
                arHistory[6].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = null;
                    } else {
                        item.meaning = null;
                    }
                });
            }
        }
    };

    const onBlurPrice = (val: any) => {
        if (typeForm === 'update') {
            const isFind = [dataRowContainer].some(
                (item: any) => item['price'] === val,
            );
            if (!isFind) {
                arHistory[7].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = dataRowContainer.currency_price;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Входящая ставка',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    } else {
                        item.meaning = val;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Входящая ставка',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    }
                });
            } else {
                arHistory[7].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = null;
                    } else {
                        item.meaning = null;
                    }
                });
            }
        }
    };

    const onSelectArrivalDate: DatePickerProps['onChange'] = (_,dateString: string | string[]) => {
        if (typeForm === 'update') {
            const isFind = [dataRowContainer].some(
                (item: any) =>
                    moment(item['arrival_date']).format('DD.MM.YYYY') === dateString,
            );
            if (!isFind) {
                arHistory[8].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = moment(dataRowContainer.arrival_date).format(
                            'DD.MM.YYYY',
                        );
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Дата прибытия',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    } else {
                        item.meaning = dateString === '' ? null : dateString;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Дата прибытия',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    }
                });
            } else {
                arHistory[8].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = null;
                    } else {
                        item.meaning = null;
                    }
                });
            }
        }
    };

    const onSelectDepartureDate: DatePickerProps['onChange'] = (_, dateString: string | string[]) => {
        if (typeForm === 'update') {
            const isFind = [dataRowContainer].some(
                (item: any) =>
                    moment(item['departure_date']).format('DD.MM.YYYY') === dateString,
            );
            if (!isFind) {
                arHistory[9].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = moment(dataRowContainer.departure_date).format(
                            'DD.MM.YYYY',
                        );
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Дата убытия',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    } else {
                        item.meaning = dateString === '' ? null : dateString;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Дата убытия',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    }
                });
            } else {
                arHistory[9].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = null;
                    } else {
                        item.meaning = null;
                    }
                });
            }
        }
    };

    const onSelectDateChangeCvv: DatePickerProps['onChange'] = (_, dateStr) => {
        setDateCvv(dateStr);
        if (typeForm === 'update') {
            const isFind = [dataRowContainer].some(
                (item: any) =>
                    moment(item['date_cvv']).format('DD.MM.YYYY') === dateStr,
            );
            if (!isFind) {
                arHistory[10].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = moment(dataRowContainer.date_cvv).format(
                            'DD.MM.YYYY',
                        );
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Дата свв',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    } else {
                        item.meaning = dateStr === '' ? null : dateStr;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Дата свв',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    }
                });
            } else {
                arHistory[10].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = null;
                    } else {
                        item.meaning = null;
                    }
                });
            }
        }
    };

    const onSelectDateChange: DatePickerProps['onChange'] = (_, dateStr) => {
        setMemory(dateStr);
        if (typeForm === 'update') {
            const isFind = [dataRowContainer].some(
                (item: any) =>
                    moment(item['date_memory']).format('DD.MM.YYYY') === dateStr,
            );
            if (!isFind) {
                arHistory[11].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = moment(dataRowContainer.date_memory).format(
                            'DD.MM.YYYY',
                        );
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Дата напоминания',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    } else {
                        item.meaning = dateStr === '' ? null : dateStr;
                        item.status_id = dataStatusContainer.find(
                            (item: any) => item.name === 'Дата напоминания',
                        )?.id;
                        item.user_id = Utils.getUserInfo().id;
                        item.container_id = dataRowContainer?.id;
                    }
                });
            } else {
                arHistory[11].map((item: any, index: number) => {
                    if (index === 0) {
                        item.meaning = null;
                    } else {
                        item.meaning = null;
                    }
                });
            }
        }
    };

    const onChangePhoto: UploadProps['customRequest'] = (value: any) => {
        setMemory(value);
        /*   if (dataRowContainer.ids_photo === null){
                updateArHistoryStatus(value, 'ids_photo', 'Добавление фото контейнера',13);
            }*/
    };

    const onChangeRemovePhoto: UploadProps['onRemove'] = (value: any) => {
        console.log(value);
        //updateArHistoryStatus(value, 'del_photo', 'Удаление фото контейнера',14);
    };

    const onSearchStock = (val: string) => {
        dispatch(getStock(val));
    }

    return (
        <>
            {contextHolder}
            <Form className='page-container' layout="vertical" autoComplete="off" onFinish={onFinish} form={form}
                  name="getValueProps">
                <Flex justify={'space-between'} className={'drawer-header'}>
                    <Flex vertical style={{width: '100%'}} gap={16}>
                        <Flex vertical style={{width: '100%'}}>
                            <div className={'drawer-title'}>{title}</div>
                        </Flex>
                        <Flex gap={24} vertical>
                            <Flex className={'container-photo'}>
                                <Uploader
                                    ids_photo={dataRowContainer?.ids_photo}
                                    setDataId={setDataId}
                                    onChangePhoto={onChangePhoto}
                                    onChangeRemovePhoto={onChangeRemovePhoto}
                                />
                            </Flex>
                            <Row gutter={[12, 8]}>
                                <Col span={8}>
                                    <Form.Item name={'num_container'}
                                               rules={[{
                                                   required: !openAddCompany,
                                                   message: 'Обязательное поле!',
                                                   min: 10, max: 11,
                                                   whitespace: true
                                               }]}>
                                        <Input placeholder={'Номер контейнера'} style={{width: '100%'}} allowClear
                                               maxLength={11}
                                               onBlur={onBlurNumContainer} value={numContainer}
                                               onChange={onChangeNumContainer}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={'type_container'}
                                               rules={[{required: !openAddCompany, message: 'Обязательное поле!' }]}>
                                        <Select
                                            disabled={!!dataRowContainer?.release}
                                            allowClear
                                            options={dataTypeContainers}
                                            onSelect={selectTypeContainer}
                                            placeholder={'Тип контейнера'}
                                            style={{width: '100%'}}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={'quality'}
                                               rules={[{required: !openAddCompany, message: 'Обязательное поле!'}]}>
                                        <Select
                                            allowClear
                                            options={dataTypeQuality}
                                            onSelect={selectQualityContainer}
                                            placeholder={'Состояние'}
                                            style={{width: '100%'}}/>
                                    </Form.Item>
                                </Col>
                                <Col span={18}>
                                    <Form.Item name={'owner'}
                                               rules={[{required: !openAddCompany, message: 'Обязательное поле!' }]}>
                                        <AutoComplete
                                            allowClear
                                            options={options}
                                            style={{width: '100%'}}
                                            onChange={handleCompany}
                                            onSelect={onSelectCompany}
                                            onSearch={(text: string) => getFindCompany(text)}
                                            placeholder="Собственник"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Button type={'text'} style={{
                                        border: '2px solid #05F',
                                        width: '100%'
                                    }} onClick={handleAddOwner}>Добавить</Button></Col>
                            </Row>
                        </Flex>
                        <Form.Item name="comment">
                            <Input.TextArea allowClear placeholder={'Комментарий'} autoSize={{minRows: 4, maxRows: 20}}
                                            onBlur={onBlurComment}/>
                        </Form.Item>
                        <Row>
                            <Col span={24}>
                                <Form.Item name={'terminal'}>
                                    <SelectSearch
                                        disabled={!!dataRowContainer?.release}
                                        defaults={terminalName}
                                        dataSource={cityData}
                                        onChangeSelect={onChangeSelect}
                                        dropdownRender={onNameChange}
                                        placeholder={'Терминал'}
                                        type={'page'}
                                        style={{width: '100%'}}
                                        hiddenBtn={false}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item name={'currency'}>
                                    <Select style={{width: '100%'}} onChange={handleCurrency}
                                            placeholder={'Валюта'} allowClear>
                                        {dataCurrency?.map((item: ICurrency) => (
                                            <Select.Option key={item.id} value={item.symbol}>
                                                <Flex align={"center"}
                                                      gap={5}>{icons[item.id]}{item.symbol} {item.value}</Flex>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'price'}>
                                    <NumericInput
                                        style={{width: '100%'}}
                                        placeholder={'Входящая ставка'}
                                        setValue={setValuePrice}
                                        value={String(valuePrice)}
                                        onBlurPrice={onBlurPrice}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item name={'arrival_date'}
                                           getValueProps={(value) => ({
                                               value: value ? dayjs(value) : null,
                                           })}>
                                    <DatePicker
                                        style={{width: '100%'}}
                                        format='DD.MM.YYYY'
                                        placeholder={'Дата прибытия'}
                                        disabledDate={(date) => disabledDateFromStartToEnd(date, dayjs())}
                                        onChange={onSelectArrivalDate}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'departure_date'}
                                           getValueProps={(value) => ({
                                               value: value ? dayjs(value) : null,
                                           })}>
                                    <DatePicker
                                        style={{width: '100%'}}
                                        format='DD.MM.YYYY'
                                        placeholder={'Дата убытия'}
                                        disabledDate={(date) => disabledDateFromStartToEnd(date, dayjs())}
                                        onChange={onSelectDepartureDate}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className={'cvv-box'} gutter={16}>
                            <Col span={12} className={'cvv-container'}>
                                <Flex vertical={false} gap={6}>
                                    <Text className={'cvv-text'}>СВВ</Text>
                                    <Icon component={deleteBlack} width={16} height={16}/>
                                </Flex>
                                <Flex style={{width: '100%'}} gap={8}>
                                    <div style={{width: '25%'}}>
                                        <Link onClick={() => setOpenCvv(!openCvv)}>
                                            <div className={'cvv-text--1'}>
                                                {dateCvv}
                                            </div>
                                        </Link>
                                        <Form.Item name={'date_cvv'} className={'cvv-text--1'}>
                                            <DatePicker
                                                open={openCvv}
                                                format='DD.MM.YYYY'
                                                onOpenChange={handleCloseCvv}
                                                style={{visibility: 'hidden'}}
                                                onChange={onSelectDateChangeCvv}
                                                disabledDate={(date) => disabledDateFromStartToEnd(date, dayjs())}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div style={{width: '75%'}}>
                                        <Link onClick={() => setOpen(!open)}>
                                            <div className={'cvv-text--2'}>
                                                Напомнить {dateMemory}
                                            </div>
                                        </Link>
                                        <Form.Item name={'date_memory'} className={'cvv-text--2'}>
                                            <DatePicker
                                                open={open}
                                                format='DD.MM.YYYY'
                                                onOpenChange={handleCloseMemory}
                                                style={{visibility: 'hidden'}}
                                                onChange={onSelectDateChange}
                                                disabledDate={(date) => disabledDateFromStartToEnd(date, dayjs())}
                                            />
                                        </Form.Item>
                                    </div>
                                </Flex>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'stock'} className={'cvv-text--2'}>
                                    <AutoComplete
                                        onSearch={onSearchStock}
                                        options={optionsStock}
                                        placeholder={'Сток'}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Text className={'text-import'}>Импорт из Excel</Text>
                        </Row>
                        <Flex gap={8} align={'flex-end'}>
                            <Button type={'primary'} htmlType={"submit"} style={{width: '70%'}} disabled={disabledSave}>
                                {typeForm === CREATE ? 'Добавить' : 'Сохранить изменения'}
                            </Button>
                            <Button type={'text'} style={{width: '30%', color: '#E14453'}}
                                    onClick={() => setConfirmation(true)}>Отменить</Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Form>

            <CustomDrawer
                open={openAddCompany}
                onClose={onCloseDrawerCompany}
            >
                <FormContractors
                    setDataRequisites={setDataRequisites}
                    setDataBankRequisites={setDataBankRequisites}
                    dataTypePhone={dataTypePhone}
                    dataTypeEmail={dataTypeEmail}
                    dataTypeMessenger={dataTypeMessenger}
                    onClose={onCloseDrawerCompany}
                    title={'Новая компания'}
                    onFinish={onFinishCompany}
                />

            </CustomDrawer>
            <ModalConfirm
                title={warningTitle}
                subtitle={subtitle}
                isOpen={confirmation}
                closeModal={() => setConfirmation(false)}
                actionAfterConsent={openAddCompany ? onCloseDrawerCompany : onClose}/>
        </>
    );
};

export default FormContainer;
