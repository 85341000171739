import React, { useEffect } from 'react';
import classnames from 'classnames';
import { IContainer, ITabForm } from '../../../../../../store/rent';
import { Flex, Form, FormListFieldData, Input, Typography } from 'antd';
import {
  FormDatePicker,
  FormInputNumber,
} from '../../../../../../components/ui/Form';
import { FORMAT_DATE_KTK, minLimitBet, nameColumnsKtk } from '../../../constants';
import { useTranslation } from 'react-i18next';
import table from '../../table.module.scss';
import { openInNewTab } from '../../../../../../helpers/link-helper';
import { RouteNames } from '../../../../../../router/names';
import { getDateString } from '../../../../../../helpers/date-helpers';
import {
  calculationContainer,
  calculationTotalContainer,
  getCountDaySnp,
  getDeliveryRequestDate,
  getTotalSnp,
} from '../../../utils';

const TabContainers: React.FC<ITabForm> = () => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();
  const containers: IContainer[] = Form.useWatch('containers', {
    form,
    preserve: true,
  });
  const bet: string = Form.useWatch('bet', {
    form,
    preserve: true,
  });
  const count_day: number = Form.useWatch('count_day', {
    form,
    preserve: true,
  });
  useEffect(() => {
    if (bet) {
      form.setFieldsValue({
        containers: containers?.map((item) => ({ ...item, bet })),
      });
    }
  }, [bet]);
  useEffect(() => {
    form.setFieldsValue({
      containers: containers?.map((cont) =>
        calculationContainer({ container: cont, count_day }),
      ),
    });
  }, [count_day]);
  return (
    <Flex vertical gap={30} style={{ marginBottom: '24px' }}>
      <Form.List name="containers">
        {(fields: FormListFieldData[], operation, meta: { errors: any }) => (
          <div className={table['block-ktk']}>
            <div
              className={table['overlap-group']}
              style={{ marginBottom: '10px' }}
            >
              <table className={table['table']}>
                <thead className={table['table__header-fixed']}>
                  <tr className={table['table__header-fixed-row']}>
                    {nameColumnsKtk.map((title) => (
                      <th
                        key={title}
                        className={classnames(
                          table['table__header-fixed-cell'],
                          table['label-card-16'],
                        )}
                        style={title === '№' ? { minWidth: 'auto' } : {}}
                      >
                        {t(title)}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className={table['table__body']}>
                  {fields.map(({ ...field }, index) => (
                    <tr
                      key={field.key}
                      className={table['table__row']}
                      data-row="row"
                    >
                      <td
                        className={classnames(table['table__cell'])}
                        style={{ minWidth: 'auto' }}
                      >
                        <Typography.Text
                          className={classnames(
                            table['text-card-14'],
                            table['pl-10'],
                          )}
                          ellipsis
                        >
                          {index + 1}
                        </Typography.Text>
                      </td>
                      <td className={classnames(table['table__cell'])}>
                        <Typography.Link
                          className={classnames(table['text-card-14'], {
                            [table['default-link']]:
                              !containers?.[field.name]?.['release']?.id,
                          })}
                          ellipsis
                          onClick={() => {
                            if (containers?.[field.name]?.['release']?.id) {
                              openInNewTab(
                                `${RouteNames.RELEASES_VIEW}${
                                  containers?.[field.name]?.['release']?.id
                                }`,
                              );
                            }
                          }}
                        >
                          {containers?.[field.name]?.['release']?.release_id}
                        </Typography.Link>
                      </td>
                      <td className={classnames(table['table__cell'])}>
                        <Typography.Link
                          className={classnames(table['text-card-14'], {
                            [table['default-link']]:
                              !containers?.[field.name]?.['id'],
                          })}
                          ellipsis
                          onClick={() => {
                            if (!containers?.[field.name]?.['id']) return;
                            openInNewTab(
                              `${RouteNames.CONTAINERS_VIEW}${
                                containers?.[field.name]?.['id']
                              }`,
                            );
                          }}
                        >
                          {containers?.[field.name]?.['num_container']}
                        </Typography.Link>
                      </td>

                      <td
                        className={classnames(
                          table['table__cell'],
                          table['text-card-14'],
                        )}
                      >
                        <Typography.Text
                          ellipsis={{
                            tooltip: {
                              placement: 'leftBottom',
                              title:
                                containers?.[field.name]?.type_container || '',
                            },
                          }}
                        >
                          {containers?.[field.name]?.['type_container'] || ''}
                        </Typography.Text>
                      </td>
                      <td
                        className={classnames(
                          table['table__cell'],
                          table['text-card-14'],
                        )}
                      >
                        <Form.Item name={[field.name, 'payment']}>
                          <FormInputNumber
                            allowClear={false}
                            onBlur={() => {
                              form.setFieldValue(
                                'total_ktk',
                                calculationTotalContainer(containers),
                              );
                            }}
                          />
                        </Form.Item>
                      </td>

                      <td
                        className={classnames(
                          table['table__cell'],
                          table['text-card-14'],
                        )}
                      >
                        <Form.Item name={[field.name, 'bet']}>
                          <FormInputNumber min={minLimitBet} allowClear={false} />
                        </Form.Item>
                      </td>
                      <td
                        className={classnames(
                          table['table__cell'],
                          table['text-card-14'],
                        )}
                        style={{ minWidth: '140px' }}
                      >
                        <FormDatePicker
                          format={FORMAT_DATE_KTK}
                          value={
                            containers?.[field.name]?.['departure_date'] || null
                          }
                          handlerChange={(str, date) => {
                            const result = containers?.[field.name];
                            result.departure_date = date;
                            const delivery_request_date =
                              getDeliveryRequestDate({
                                departure_date: date,
                                count_day,
                              });
                            const count_day_snp = getCountDaySnp({
                              delivery_date: result.delivery_date,
                              delivery_request_date,
                            }) as number;
                            const total_snp = getTotalSnp({
                              count_day,
                              count_day_snp,
                            });
                            result.total_snp = total_snp + '';
                            result.delivery_request_date =
                              delivery_request_date;
                            result.count_day_snp = count_day_snp + '';

                            form.setFieldValue(
                              ['containers', field.name],
                              result,
                            );
                          }}
                        />
                      </td>
                      <td
                        className={classnames(
                          table['table__cell'],
                          table['text-card-14'],
                        )}
                        style={{ minWidth: '140px' }}
                      >
                        <Typography.Text
                          className={table['text-card-14']}
                          ellipsis={{
                            tooltip: {
                              placement: 'topLeft',
                              title: getDateString({
                                date: containers?.[field.name]?.[
                                  'delivery_request_date'
                                ],
                              }),
                            },
                          }}
                        >
                          {getDateString({
                            date: containers?.[field.name]?.[
                              'delivery_request_date'
                            ],
                          })}
                        </Typography.Text>
                      </td>
                      <td
                        className={classnames(
                          table['table__cell'],
                          table['text-card-14'],
                        )}
                        style={{ minWidth: '140px' }}
                      >
                        <FormDatePicker
                          format={FORMAT_DATE_KTK}
                          value={
                            containers?.[field.name]?.['delivery_date'] || null
                          }
                          handlerChange={(str, date) => {
                            const result = containers?.[field.name];
                            result.delivery_date = date;
                            const count_day_snp = getCountDaySnp({
                              delivery_date: date,
                              delivery_request_date:
                                result.delivery_request_date,
                            }) as number;
                            const total_snp = getTotalSnp({
                              count_day,
                              count_day_snp,
                            });
                            result.total_snp = total_snp + '';
                            result.count_day_snp = count_day_snp + '';

                            form.setFieldValue(
                              ['containers', field.name],
                              result,
                            );
                          }}
                        />
                      </td>
                      <td
                        className={classnames(
                          table['table__cell'],
                          table['text-card-14'],
                        )}
                      >
                        <Typography.Text
                          className={table['text-card-14']}
                          ellipsis={{
                            tooltip: {
                              placement: 'topLeft',
                              title:
                                containers?.[field.name]?.['count_day_snp'] ??
                                '',
                            },
                          }}
                        >
                          {containers?.[field.name]?.['count_day_snp'] ?? ''}
                        </Typography.Text>
                      </td>
                      <td
                        className={classnames(
                          table['table__cell'],
                          table['text-card-14'],
                        )}
                      >
                        <Typography.Text
                          className={table['text-card-14']}
                          ellipsis={{
                            tooltip: {
                              placement: 'topLeft',
                              title:
                                containers?.[field.name]?.['total_snp'] ?? '',
                            },
                          }}
                        >
                          {containers?.[field.name]?.['total_snp'] ?? ''}
                        </Typography.Text>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Form.List>
      <Form.Item label={t('Итого')} name="total_ktk">
        <Input disabled />
      </Form.Item>
      <Form.Item label={t('Остаток долга')} name="balance_ktk">
        <Input disabled />
      </Form.Item>
    </Flex>
  );
};

export default TabContainers;
