import React from 'react';
import {
  Button,
  Col,
  Flex,
  Form,
  FormListFieldData,
  Input,
  Row,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import table from '../../table.module.scss';
import style from '../../../style.module.scss';
import { defaultExpenses, IExpenses } from '../../../../../../store/rent';
import TooltipButton from '../../../../../../components/ui/TooltipButton/TooltipButton';
import IconButton from '../../../../../../components/ui/IconButton';

export const TabExpenses = () => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();

  const id: number = Form.useWatch('id', {
    form,
    preserve: true,
  });
  const bet: string = Form.useWatch('bet', {
    form,
    preserve: true,
  });
  const expenses: IExpenses[] = Form.useWatch('expenses', {
    form,
    preserve: true,
  });

  return (
    <Flex vertical gap={30} style={{ marginBottom: '24px' }}>
      <Form.List name="expenses">
        {(fields: FormListFieldData[], operation, meta: { errors: any }) => (
          <div className={table['block-ktk']}>
            <div
              className={table['overlap-group']}
              style={{
                marginBottom: '10px',
              }}
            >
              <Flex vertical gap={10}>
                <Row gutter={24}>
                  <Col span={6}>
                    <Typography.Text className={style.label}>
                      {t('Название')}
                    </Typography.Text>
                  </Col>
                  <Col span={6}>
                    <Typography.Text className={style.label}>
                      {t('Оплата поставщику')}
                    </Typography.Text>
                  </Col>
                  <Col span={6}>
                    <Typography.Text className={style.label}>
                      {t('Ставка клиенту деньги')}
                    </Typography.Text>
                  </Col>
                  <Col span={6}>
                    <Typography.Text className={style.label}>
                      {t('Согласование')}
                    </Typography.Text>
                  </Col>
                </Row>
                {fields?.map(({ ...field }, index: number) => (
                  <Row gutter={24} key={field.key}>
                    <Col span={6}>
                      <Form.Item name={[field.name, 'title']} rules={[{required: true,  message: t('Обязательное поле'), whitespace: true}]}>
                        <Input placeholder={t('Название')} disabled={expenses?.[field.name]?.agreed} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name={[field.name, 'payment']}>
                        <Input placeholder={t('Оплата поставщику')} disabled={expenses?.[field.name]?.agreed} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name={[field.name, 'bet']}>
                        <Input placeholder={t('Ставка клиенту деньги')} disabled={expenses?.[field.name]?.agreed} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Flex gap={10}>
                        <TooltipButton
                          propsButton={{
                            type: 'primary',
                            style: {
                              minHeight: '36px',
                              background: !id || expenses?.[field.name]?.agreed
                                ? '#E6F6EC'
                                : '#00B288',
                            },
                            disabled: !id || expenses?.[field.name]?.agreed,
                            onClick: () => {
                              const result = { ...expenses[field.name] };
                              result['agreed'] =
                                !expenses?.[field.name]?.agreed;
                              form.setFieldValue(
                                ['expenses', field.name],
                                result,
                              );
                            },
                          }}
                        >
                          {!id || !expenses?.[field.name]?.agreed ? t('Не согласовано') : t('Согласовано')}
                        </TooltipButton>
                        {!expenses?.[field.name]?.agreed && (
                          <IconButton
                            iType="delete"
                            style={{
                              border: 'none',
                              background: 'none',
                              color: '#E14453',
                            }}
                            onClick={() => {
                              operation.remove(field.name);
                            }}
                          />
                        )}
                      </Flex>
                    </Col>
                  </Row>
                ))}
              </Flex>
            </div>
            <Row gutter={24}>
              <Col span={8} style={{ paddingLeft: '30px' }}>
                <TooltipButton
                  propsButton={{
                    type: 'primary',
                    color: 'success',
                    onClick: () => operation.add(defaultExpenses, 0),
                  }}
                >
                  {t('Добавить')}
                </TooltipButton>
              </Col>
            </Row>
          </div>
        )}
      </Form.List>
    </Flex>
  );
};

export default TabExpenses;
