import { Col, Flex, Form, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInputNumber } from '../../../../../../components/ui/Form';
import { ITabForm } from '../../../../../../store/sales';

const TabRepair: React.FC<ITabForm> = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const amount_client: number = Form.useWatch('amount_client', {
    form,
    preserve: true,
  });
  const amount_us: number = Form.useWatch('amount_us', {
    form,
    preserve: true,
  });

  useEffect(() => {
    form.setFieldValue(
      'total_repair',
      Number(amount_client) + Number(amount_us),
    );
  }, [amount_us, amount_client]);

  return (
    <Flex vertical>
      <Flex vertical gap={30} style={{ marginBottom: '24px' }}>
        <Row gutter={[14, 10]}>
          <Col span={12}>
            <Form.Item label={t('Сумма клиенту')} name="amount_client">
              <FormInputNumber placeholder="" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('Сумма нам')} name="amount_us">
              <FormInputNumber placeholder="" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={t('Итого')} name="total_repair">
          <FormInputNumber placeholder="" disabled />
        </Form.Item>
      </Flex>
    </Flex>
  );
};

export default TabRepair;
